import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import MainPage from './pages/main_paage';

function App() {
  return (
    <Router>
      <div classname="App">
        <Routes>
          <Route path="/" element={<MainPage/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
