import React, { useState } from 'react';
import "./main-paage.css";

import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';  

// DIALOG IMPORTS
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function MainPage() {
  const [button1Size, setButton1Size] = useState(1); // Initial size of Button 1
  const [button2Size, setButton2Size] = useState(1); // Initial size of Button 2
  const [open, setOpen] = React.useState(false);

  const handleButtonClick = () => {
    // Increase the size of Button 1
    setButton1Size(prevSize => prevSize + 1);
    // Decrease the size of Button 2
    setButton2Size(prevSize => prevSize - 0.3);
  };

  const handleClickOpen = () => {
    if (window.confirm('This is to inform you that once the dialog is open, You will be mine forever.')) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const agreeSelect = () => {
    alert('Thank you for accepting. I hope our relationship will be stronger forever!');
    const newWindow = window.open('', '_self');
    newWindow.close();
}

  return (
    <div className='main-page-container'>
      <h1>Will you be my Valentine</h1>
      <h3>Ms. Nellyn May Joy P. Ibañez?</h3>
      <div className="main-page-buttons">
        <div className="button1">
          <Button variant="outlined" color='inherit' startIcon={<FavoriteIcon />} style={{ fontSize: `${button1Size}rem` }} sx={{color:'black'}} onClick={handleClickOpen} >
            YES
          </Button>
        </div>
        <p>or</p>
        <div className="button2">
          <Button variant="outlined" color='inherit' startIcon={<HeartBrokenIcon />} style={{ fontSize: `${button2Size}rem` }} sx={{color:'black'}} onClick={handleButtonClick}>
            NO
          </Button>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Valentine's Day Date Invitation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Dear My Love,<br/><br/>

          I hope this letter finds you in good spirits. As Valentine's Day approaches, I find myself compelled to reach out to you with a heartfelt invitation.<br/><br/>

          In celebration of this special day, I would be honored to have you accompany me on a Valentine's Day date tomorrow, February 14,2024. It would be a wonderful opportunity for us to spend quality time together and create cherished memories.<br/><br/>

          Here are the details of our Valentine's Day date:<br/><br/>

          Date: February 14, 2024<br/>
          Time: 6:00 pm onwards<br/>
          Location: Wherever you want to go.<br/><br/>

          I believe that our time together would be filled with laughter, joy, and love, as we embrace the spirit of Valentine's Day. Your presence would mean the world to me, and I cannot think of a better way to spend this romantic occasion than with you.<br/><br/>

          Please let me know if you are able to join me. Your response would be greatly appreciated, and I eagerly await your reply.<br/><br/>

          Thank you for considering my invitation. Regardless of your decision, I want you to know that you hold a special place in my heart.<br/><br/>

          With warmest regards and affection,<br/><br/>

          Your loving future Husband.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={agreeSelect} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MainPage;
